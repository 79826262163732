import {NavLink} from 'react-router-dom'
import { useState } from 'react'
import { HashLink } from 'react-router-hash-link'

export default function Header(){
    
    // States
    const [toggle , setToggle] = useState(false)
    const [styleHeader, setStyleHeader] = useState(false)

    // Functions
    const toggler = () => {
        toggle ? setToggle(false) : setToggle(true)
    }

    const closeMenu = () => {
        setToggle(false)
    }

    const styleOnScroll = () => {
        let scrollPos = window.pageYOffset;
        if(scrollPos > 0){
            setStyleHeader(true)
        }else{
            setStyleHeader(false)
        }
    }

    const scrollWithOffset = (el, offset) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = offset; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    window.addEventListener('scroll', styleOnScroll)

    // GSAP ANIMATIONS    

    return(
        <header className={styleHeader ? 'scrolled' : 'not-scrolled'}>
            <div className="upper-header">
                <div className="container">
                    <span className='call-us'>Call us: +1 (954) 326-9797</span>
                </div>
            </div>
            <div className="container">
                <NavLink onClick={closeMenu} to={'/'} className="logo-link">Home</NavLink>
                <nav className={toggle ? 'active' : 'hidden'}>
                    <ul>
                        <li><HashLink smooth={true} to={'/#top'}>Home</HashLink></li>
                        <li><HashLink
                            scroll={el => scrollWithOffset(el, -250)}
                            onClick={closeMenu}
                            to='/#us'
                            
                            smooth={true}
                            >About Us</HashLink></li>
                        <li><HashLink
                            scroll={el => scrollWithOffset(el, window.innerWidth < 760 ? 470 : -80)}
                            onClick={closeMenu}
                            to='/#services'
                            smooth={true}
                            >Our services</HashLink></li>
                        <li><HashLink 
                            scroll={el => scrollWithOffset(el, -80)}
                            onClick={closeMenu}
                            to='/#proyects'
                            smooth={true}
                            >Projects</HashLink></li>
                        <li><a href="https://wa.me/19543269797" target='_blank' onClick={closeMenu} >Contact us</a></li>
                    </ul>                    
                </nav>
                <div onClick={toggler} className={`burger-box ${toggle ? 'clicked' : ''}`}>
                    <div></div>
                </div>
            </div>
        </header>
    )
}