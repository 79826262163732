
import { BrowserRouter } from "react-router-dom";
import NotFound from './pages/404';
import Service from './pages/Service'
import Home from "./pages/Home";
import { Routes, Route } from "react-router-dom";
import ThankYou from "./pages/ThankYou";

function App() {

  return (
    <div className="App">
      <BrowserRouter>  
          <Routes>                
              <Route path="/" element={<Home />}></Route>
              <Route path="/:service" element={<Service/>}></Route>
              <Route path="/thank-you" element={<ThankYou/>}></Route>
              <Route path='*' element={<NotFound />}></Route>  
          </Routes>        
      </BrowserRouter>
    </div>
  );
}

export default App;
