import { NavLink } from "react-router-dom"
import services from "../data/services.json"

export default function Services(){

    const handleClick = ()=>{
        window.scrollTo(0)
    }

    return(
        <section id="services">
            <div className="container">
                <span className="h2-before">What we solve</span>
                <h2><span>Our </span>Services</h2>
                <ul>                    
                {services.map(service => {
                    return <li key={service.id} className={service["route-name"]}>
                        <div className="grid">
                            <div className="content">
                                <h3>{service.name}</h3>
                                <p>{service["first_text"]}</p>
                                <NavLink onClick={handleClick} className={'service-link'} to={`/${service["route-name"]}`}>See more</NavLink>                                
                            </div>
                            <picture>
                                <source srcSet={`../assets/img/${service.homeImgs.pc}.webp`} media="(min-width: 768px)"/>
                                <img src={`../assets/img/${service.homeImgs.mobile}.webp`} alt="Employee plainting" />
                            </picture>
                        </div>
                        </li>
                })} 

                    {/* <li className="painting">
                        <div className="grid">
                            <div className="content">
                                <h3>Painting</h3>
                                <p>We specialise in reviving multi-storey buildings for Strata government and commercial which include hospitals, sport stadiums, airports, industrial facilities as well as civil structures such as bridges and piers.</p>
                                <NavLink onClick={handleClick} className={'service-link'} to='/painting'>See more</NavLink>                                
                            </div>
                            <picture>
                                <source srcSet="../assets/img/painting-home-pc.webp" media="(min-width: 768px)"/>
                                <img src="../assets/img/painting.webp" alt="Image of employee plainting" />
                            </picture>
                        </div>
                    </li>
                    <li className="waterproofin">
                        <div className="grid">
                            <div className="content">
                                <h3>Waterproofin</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit veniam adipisci sunt. Voluptatem, debitis cumque.</p>
                                <NavLink onClick={handleClick} className={'service-link'} to='/waterproofin'>See more</NavLink> 
                            </div>
                            <picture>
                                <source srcSet="../assets/img/waterproofing-home-pc.webp" media="(min-width: 768px)"/>
                                <img src="../assets/img/waterproofin.webp" alt="Image of employee plainting" />
                            </picture>
                        </div>
                    </li> */}
                </ul>
            </div>
        </section>
    )
}