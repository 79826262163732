import { NavLink } from "react-router-dom"

export default function Footer(){
    return(
        <footer>
            <div className="container">
                <div className="footer-flex-container">
                    <NavLink to={'/'} className='logo-link'>Home</NavLink>
                    <span className="footer-content">
                        <span className="footer-heading">General enquiries</span>
                        <span className="footer-info">info@abseilersunited.com</span>
                        <span className="footer-info">+1 (954) 326-9797</span>
                    </span>
                    <span className="footer-content">
                        <span className="footer-heading">Privacy</span>
                        <span className="footer-info">Design: <a href="#">Gonzalo Bordes</a></span>
                        <span className="footer-info">Code: <a href="#">Gonzalo Bordes</a></span>
                    </span>
                </div>                
                <span className="copyright">Copyright ©2024 Barr Build. All Rights Reserved. Barr Group Ltd t/a Barr Build.</span>
            </div>
        </footer>
    )
}