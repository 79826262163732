import Form from "./Form"
import { useEffect } from "react";

export default function Hero(){

    useEffect(() => {
        // Selecciona el video
        const video = document.getElementById('hero-video');

        // Opciones para el Intersection Observer
        const options = {
            root: null, // El viewport es el contenedor por defecto
            rootMargin: '0px',
            threshold: 0.5 // Se activa cuando al menos el 50% del elemento está visible
        };

        // Callback para el Intersection Observer
        const callback = (entries) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                // Si el video está visible, reproducelo
                video.play();
            } else {
                // Si el video no está visible, pausalo
                video.pause();
            }
            });
        };

        // Crea el Intersection Observer
        const observer = new IntersectionObserver(callback, options);

        // Observa el video
        observer.observe(video);
    }, []);
    

    return(
        <section id="hero">
             <video id="hero-video" autoPlay muted playsInline loop preload="true" src="./assets/img/gero-sky.mp4"></video>
            <div className="container">
                <div className="content">
                    <h1 >Miami's <span>leading rope access</span> experts</h1>
                    <span className="anim-heading">
                        <span  className="anim-box">
                            <span>Miami's</span>  
                        </span>
                        <span  className="anim-box">
                            <span>leading</span>
                        </span>
                        <span  className="anim-box">
                            <span>rope</span>  
                        </span>
                        <span  className="anim-box">
                            <span>access</span>  
                        </span>
                        <span  className="anim-box">
                            <span>experts</span> 
                        </span>
                    </span>
                    <div className="anim-box">
                        <p>Save up to 60% compared to conventional access methods</p>
                    </div>                      
                </div>                                
                <div className="box">
                    <Form />           
                </div>
            </div>
        </section>
    )
}