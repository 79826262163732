import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Form(){ 
    
    const navigate = useNavigate()

    const formRef = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
          .sendForm('service_t9ikrth', 'template_04p2wfp', formRef.current,"DnndFsOvzJDaQfV_W", {
            publicKey: 'YOUR_PUBLIC_KEY',
          })
          .then(
            () => {
              console.log('SUCCESS!');
              navigate('/thank-you')
            },
            (error) => {
              console.log('FAILED...', error.text);
            },
          );
      }
    
    return(
        <div className="form-box">
                <h3>Get a free <br /> quote now!</h3>
                <p>Tell us About Your Project and we will get back to you!</p>
                <form ref={formRef} onSubmit={sendEmail}>
                    <input required name="name" placeholder="Your Name" type="text" />
                    <input required name="email" placeholder="Your Email" type="text" />
                    <input name="phone" placeholder="Your Phone Number" type="number" />
                    <textarea required placeholder="Enquiry Notes" name="message" id="notes" cols="30" rows="10"></textarea>
                    <input type="submit" value="Get a quote" />
                </form>
        </div>         
    )
}