import Header from "../components/Header"
import Footer from "../components/Footer"

export default function notFound(){

    return(
        <>  
            <Header/>
            <div className="404">
                <h2>Not found</h2>
            </div>
            <Footer/>
        </> 
    )
}