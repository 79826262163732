import { useEffect,useState } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Hero from '../components/Hero'
import Services from '../components/Services'
import Us from '../components/Us'
import Projects from '../components/Projects'
import Testimonials from '../components/Testimonials'


export default function Home(){
    gsap.registerPlugin(ScrollTrigger)

    // STATES
    const [loading, setLoading] = useState(false)

    // USE EFFECT
    useEffect(() => {

        // SPLASH SCREEN TIMEOUT
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000);

        const svg = document.querySelector('.splash svg')
        svg.classList.add('active')

        // GSAP ANIMATIONS
        gsap.fromTo('header',{opacity:0}, { opacity:1, duration: 1.5, delay: 4.9})
        gsap.fromTo('.anim-heading span',{y:50}, {y:0, duration: .5, delay: 3.2, stagger: .06 ,ease: "Circ.easeOut"})
        gsap.fromTo('#hero p',{y:50},{y:0, duration: .5, delay: 3.9, ease: "Circ.easeOut"})
        gsap.fromTo('.form-box',{opacity:0, y:20}, {opacity:1, y:0, delay: 4.3, duration: .8})
        gsap.fromTo('#services .content', {opacity:0}, {opacity: 1, duration: 1})   
        
        gsap.utils.toArray('#services img').forEach(img =>{
            const tl = gsap.timeline({
                scrollTrigger:{
                    trigger: img.parentElement.parentElement.parentElement.parentElement,
                    start: "top 100%", 
                    end: "bottom 0%",
                    scrub: true
                }
            })
            tl.fromTo(img,{ y: "-20%"},{ y:"20%"})
        }) 
        
        gsap.utils.toArray('#services .content').forEach(content =>{

            const contentTl = gsap.timeline({
                scrollTrigger:{
                    trigger: content,
                    scrub: true,         
                }
            })            
            contentTl.fromTo(content,{y: 25}, {y: -25})
        }) 
        
    }, []);

    return(
        <>   
            <Header/>        
                <main>
                    <Hero />
                    <Services />
                    <Us />
                    <Projects />
                    <Testimonials />
                    <div className={`splash  ${loading ? 'visible' : 'hidden'}`}>
                        <div className='logo-box'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 183.43 189.23" width="140" height="189.22999572753906"><defs><style></style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1 svg-elem-1" d="M182.88,86.66H162.29c-.78-3.79-1.53-7.48-2.33-11.37H23.39C22.71,79,22,82.62,21.31,86.6H.55C3.29,56,16.67,31.76,42.46,15.68c32.47-20.24,66-20.24,98.49,0C166.74,31.76,180.16,56,182.88,86.66ZM32.41,54.11H150.9c-13.6-19.34-31.29-30.47-54.19-31.94C69.72,20.44,48.67,31.61,32.41,54.11Z"></path><path className="cls-1 svg-elem-2" d="M58.94,108.8H80.43V87.37h22.3V108.5h21.75v22.86H102.93v21.36H80.71V131.63H58.94Z"></path><path className="cls-1 svg-elem-3" d="M1.09,103.07H19.86c3.31,9.29,5.43,18.79,9.88,27,13.12,24.37,34.38,37.28,61.81,37.27,27.22,0,48.41-12.69,61.52-36.89,3.92-7.25,5.66-15.73,8-23.76.76-2.55,1.28-4.13,4.39-4,5.44.22,10.89.06,17.17.06-2.66,30.49-16,54.44-41.19,70.44-32.24,20.45-65.71,20.6-98.53.92C15.55,157.71.77,127.17,1.09,103.07Z"></path></g></g></svg>
                        </div>
                    </div>
                </main>
            <Footer/> 
        </>        
    )
}