import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination} from 'swiper';
import testimonials from '../data/testimonials.json'

import 'swiper/css';
import 'swiper/css/pagination'

export default function Testimonials(){
  return (

    <section id="testimonials">
        <div className="container">
            <span className="h2-before">What our clients say</span>
            <h2>Testimonials</h2>
            <Swiper
            modules={[Pagination]}
            pagination={true}
            breakpoints={{
                760: {
                    slidesPerView: 2
                }
            }}
            spaceBetween={50}
            slidesPerView={1}
            loop={"true"}
            draggable={"true"}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            >
                {
                    testimonials.map(function(testimonial, i){
                        return <SwiperSlide key={i}>
                                    <li>
                                        <div className="header">
                                            <div className="content">
                                                <h3>{testimonial.name}</h3>
                                                <span>{testimonial['Project-name']}</span>
                                            </div>
                                            {/* <img src={testimonial.image} alt="" /> */}
                                        </div>
                                        <div className="footer">
                                            <p>"{testimonial.testimonial}"</p>
                                        </div>
                                    </li>                                    
                                </SwiperSlide>                                               
                    })
                }
            </Swiper>  
        </div>
    </section>    
  )
};