import { HashLink } from "react-router-hash-link"


export default function Us(){

    const scrollWithOffset = (el, offset) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = offset; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    return(
        <section id="us">
            <div className="container">
                <div className="content">
                    <span className="h2-before">Get to know us</span>
                    <h2><span>Who</span> are we?</h2>
                    <p>Welcome to Abseilers United. We are Sydney industrial rope access, height safety, and confined spaces work experts. </p>
                    <p>A team of passionate and professional abseiling aficionados with years of best practice commercial experience, we bring exceptional results to every project and client.</p>
                    <HashLink to="/#proyects" smooth={true} scroll={el => scrollWithOffset(el, -80)} className="proyect-link">Latest projects</HashLink>
                </div>
                <picture>
                    <source srcSet="../assets/img/about-us-pc.webp" media="(min-width: 768px)"/>
                    <img src="./assets/img/about-us.webp" alt="employee working" />
                </picture>                
            </div>
        </section>
    )
}