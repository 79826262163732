import {useParams} from 'react-router-dom'
import servicesJson from '../data/services.json'
import NotFound from './404'
import Form from '../components/Form'
import Header from "../components/Header"
import Footer from "../components/Footer"
import { useEffect } from 'react'
import gsap from 'gsap'

export default function Service(){
    const params = useParams()
    let renderedService = ''
    servicesJson.forEach(loopedService => {
        if(loopedService['route-name'] === params.service){
            renderedService = loopedService
        }
    })

    useEffect(() => {
        // GSAP ANIMATIONS  
        gsap.fromTo('header',{opacity:0}, { opacity:1, duration: 1.5 })
        gsap.fromTo('h1',{y:"80%"}, { y:0 , duration: 1.3, delay: .4, ease: "power2.out" })

        
    }, []);   

    if(renderedService.length < 1){
        return <NotFound/> 
    }else{
        return(
            <>
                <Header/>
                <main>                
                    <section id="service-hero">
                        <div className="container">
                        <div className="anim-box">
                            <h1>{renderedService.name}</h1>         
                        </div>
                        </div>
                        <img src={`./assets/img/${renderedService.heroImg}`} alt="" />               
                    </section>
                    <section id="service-info">
                        <div className="container">
                            <article id="first-article">
                                <div className="content">
                                    <h2><span>High rise </span>{renderedService.name}</h2>
                                    <p>{renderedService["first_text"]}</p>
                                </div>
                                <picture>
                                <source srcSet={`../assets/img/${renderedService.detailImgs.pc}.webp`} media="(min-width: 768px)"/>
                                    <img src={`./assets/img/${renderedService.detailImgs.mobile}.webp`} alt="" />
                                </picture>                           
                            </article>
                            <article id="second-article">
                                <div className="content">
                                       {
                                        renderedService.second_heading && (
                                            <h2>{renderedService.second_heading}</h2>
                                        )
                                       }

                                       {
                                        !renderedService.second_heading && (
                                            <h2>{renderedService.name} on:</h2>
                                        )
                                       }
                                                                        
                                    <p>{renderedService["second-text"]}</p>
                                </div>
                                <Form />
                            </article>
                            <img src="./assets/img/service-illustration.svg" alt="" /> 
                        </div>
                    </section>
                </main>
                <Footer/>
            </>                       
        )
    }    
}