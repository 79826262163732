import projects from '../data/projects.json'
import { NavLink } from 'react-router-dom'

export default function Projects(){

    const handleClick = ()=>{
        window.scrollTo(0)
    }

    return(
        <section id="proyects">
            <div className="container">
                <span className="h2-before">Our work</span>
                <h2><span>Latest </span>projects</h2>
                <ul className="proyect-list">
                    {
                        projects.map(function(project , i){
                            return  <li className='proyect-item' key={i}>
                                        <div className="content">
                                            <h3>{project.name}</h3>
                                            <p>{project.desc}</p>
                                            <div className="service-label-list">
                                                {project.service.map(service =>{
                                                    return <NavLink onClick={handleClick} key={service} className='proyect-link' to={service}>{service}</NavLink>
                                                })}    
                                            </div>                                                                          
                                        </div>
                                        <picture>
                                            <img src={`./assets/img/${project.img}.webp`} alt="" />
                                        </picture>
                                         
                                    </li>
                        })
                    }
                </ul>
            </div>
        </section>
    )
}